<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex justify-content-between align-items-center p-1"
    >
      <h2>Naqdga sotilgan mijozlar ro'yhati <b v-if="contracts && contracts.total">({{ contracts.total }} ta)</b></h2>
      <b-overlay :show="loadingBtn">
        <b-button
          variant="outline-success"
          class="float-right"
          size="small"
          @click="tableToExcel"
        >
          <img
            src="/images/excel_1.svg"
            alt=""
          >
        </b-button>
      </b-overlay>
    </div>
    <div>
      <b-row>
        <b-col
          :md="7"
          class="d-flex flex-wrap flex-row"
        >
          <x-date-picker
            v-model="filterModel.from_date"
            :placeholder="$t('Дата начала')"
            class="mr-1 mb-1"
          />
          <x-date-picker
            v-model="filterModel.to_date"
            :placeholder="$t('Дата окончания')"
            class="mr-1 mb-1"
          />
        </b-col>
      </b-row>
    </div>
    <b-overlay
      v-if="contracts"
      :show="loading"
    >
      <good-table-contracts
        :items="contracts.data"
        :columns="columns"
        :total="contracts.total"
        :page="page"
        :has-show="true"
        model="CashOrder"
        :filter="filterModel"
        @getItems="getItems"
        @onPageChange="(p) => (page = p)"
      >
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'productsList'">
            <div v-if="props.row.products && props.row.products.length">
              <p
                v-for="(item,i) in props.row.products"
                :key="item.id"
              ><b>{{ i+1 }}. </b> {{ item.category.name_uz }}</p>
            </div>
          </span>
        </template>
      </good-table-contracts>
      <good-table-contracts
        v-if="contractsX"
        v-show="false"
        ref="table"
        :items="contractsX.data"
        :columns="columns"
        :total="contractsX.total"
        :page="page"
        :has-show="true"
        class="tablee"
        model="CashOrder"
        :filter="filterModel"
        @getItems="getItems"
        @onPageChange="(p) => (page = p)"
      >
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'productsList'">
            <div v-if="props.row.products && props.row.products.length">
              <p
                v-for="(item,i) in props.row.products"
                :key="item.id"
              ><b>{{ i+1 }}. </b> {{ item.category.name_uz }}</p>
            </div>
          </span>
        </template>
      </good-table-contracts>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import GoodTableContracts from '@/views/table/vue-good-table/GoodTableContracts.vue'

export default {
  name: 'Index',
  components: {
    GoodTableContracts,
  },
  props: {
    // contracts: {
    //   type: [Array, Object],
    //   default: () => null,
    // },
  },
  data() {
    return {
      page: 1,
      loading: false,
      statusTab: 1,
      contracts: null,
      contractsX: null,
      loadingBtn: false,
      filterModel: {
        per_page: 50,
        type: 3,
        relations: 'createdUser|products.category',
        from_date: null,
        to_date: null,
      },
      title: "Naqdga sotilganlar ro'yhati",
      uri: 'data:application/vnd.ms-excel;base64,',
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)))
      },
      format(s, c) {
        return s.replace(/{(\w+)}/g, (m, p) => c[p])
      },
    }
  },
  computed: {
    columns() {
      return [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('ФИО'),
          field: 'bought_for_cash_client_full_name',
          showCashField: this.hasPermission('sell_for_cash'),
        },
        {
          label: this.$t('Телефон'),
          field: 'bought_for_cash_client_phone',
        },
        {
          label: this.$t('Дата'),
          field: 'created_at',
          isDate: true,
        },
        {
          label: this.$t('Кто зарегистрирован'),
          field: 'created_user.name',
        },
        {
          label: 'Mahsulotlar',
          field: 'productsList',
          // field: this.passportField,
        },
        {
          label: this.$t('Цена'),
          field: 'amount',
          class: 'text-nowrap',
          isAmount: true,
        },
        {
          label: this.$t('Квитанция'),
          field: 'print_cash',
        },
      ]
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.from_date': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.to_date': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      getPaymentsAction: 'contract/paymentList',
    }),
    passportField(row) {
      if (row.products && row.products.length) {
        return row.products.map(el => el.category.name_uz)
      }
      return ''
    },
    async getItems() {
      this.loading = true
      await this.getPaymentsAction({
        ...this.filterModel,
        page: this.page,
      }).then(res => {
        this.contracts = res.data
      })
      this.loading = false
    },
    async getItemsX() {
      this.loading = true
      await this.getPaymentsAction({
        per_page: 9999,
        type: 3,
        from_date: this.filterModel.from_date,
        to_date: this.filterModel.to_date,
        relations: 'createdUser|products.category',
        page: 1,
      }).then(res => {
        this.contractsX = res.data
      })
      this.loading = false
    },
    async tableToExcel(table) {
      this.loadingBtn = true
      await this.getItemsX()

      const tableeee = document.querySelector(
        'div.tablee div.vgt-responsive table',
      )

      const rows = tableeee.querySelectorAll('table tr')
      rows.forEach(row => {
        const lastCell = row.lastElementChild
        if (lastCell.querySelector('td.vgt-left-align span.link')) {
          lastCell.remove()
        }
      })

      setTimeout(() => {
        if (!table.nodeType) table = tableeee
        const ctx = { worksheet: `${this.title}`, table: table.innerHTML }
        const link = document.createElement('a')
        link.download = `${this.title}.xls`
        link.href = this.uri + this.base64(this.format(this.template, ctx))
        link.click()
        this.loadingBtn = false
      }, 100)
    },
  },
}
</script>

<style scoped></style>
